import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { AmlService } from 'src/app/services/aml.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import type {
  Question,
  MetaQuestion,
  EmptyAnswer,
  Answer,
  Upload,
} from '../../types/index';
import { User } from '@sharedmodels/user.model';
import { NavigationService } from '../services/navigation.service';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-aml',
  templateUrl: './aml.component.html',
})
export class AmlComponent implements OnInit {
  question = <Question>{};
  questions: Question[];
  user = <User>{};
  meta_question = <MetaQuestion>{};
  meta_questions: MetaQuestion[];
  empty_answers: EmptyAnswer[];
  empty_answer = <EmptyAnswer>{};
  answers: Answer[];
  answer = <Answer>{};
  radioSelected: any;
  input: string;
  isEnabled = true;
  questionsForm: FormGroup;
  loading: boolean = false;
  files: Upload[] = [];
  file: Upload = <Upload>{};
  public obj: any = {};
  url: SafeUrl;
  info: string = '';
  error: string = '';
  uploadinfo: FormGroup;
  unanswered_questions = 0;

  @Output() goForward = new EventEmitter<boolean>();

  constructor(
    private amlService: AmlService,
    public authService: AuthService,
    private router: Router,
    public notificationService: NotificationService,
    public navigationService: NavigationService,
    public cdf: ChangeDetectorRef,
    public sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.fetchQuestions();
  }

  getLogin() {
    this.authService.getLogin().subscribe((user: User) => {
      this.user = user;
      if (this.user?.has_questions) {
        this.goForward.emit(false);
      } else {
        this.goForward.emit(true);
      }
    });
  }

  fetchQuestions() {
    this.loading = true;
    this.amlService.getQuestions().subscribe((res: MetaQuestion[]) => {
      this.loading = false;
      this.unanswered_questions = 0;
      this.meta_questions = [];
      this.meta_questions = [...res];
      if (this.meta_questions.length > 0) {
        this.meta_questions?.forEach((mq) => {
          mq.questions?.forEach((question) => {
            if (
              question.answers === null ||
              (question.answers?.length > 0 &&
                question.answers[0].upload === true &&
                question.answers[0].files === null)
            ) {
              question.answered = false;
              this.unanswered_questions++;
            } else {
              question.answered = true;
            }
            question.isLoading = false;
          });

          mq.questions?.forEach((question) => {
            question?.answers?.forEach((answer) => {
              if (answer?.answer?.toLocaleLowerCase() == 'andet') {
                question.showOther = true;
              }
            });
          });

          this.cdf.detectChanges();
          setTimeout(() => {
            this.notificationService.setNotObs(true);
          });
        });
      }
      this.getLogin();
    });
  }

  toggle(event, q: Question) {
    q.showOther = false;
    let value: string;
    value = event?.target?.value?.toLocaleLowerCase();
    if (value === 'andet') {
      q.showOther = true;
    }
  }

  saveOther(question: Question) {
    this.loading = false;

    let id: number;
    let other: string = '';

    const answer = { answer_id: id, answer_other: other };

    if (question?.empty_answer) {
      question.empty_answer?.forEach((empty_answer) => {
        if (empty_answer?.other_yn.toLocaleLowerCase() === 'y') {
          answer.answer_id = empty_answer.answer_id;
        }
      });
    }

    answer.answer_other = (
      document.getElementById('id-' + question.ask_id) as HTMLInputElement
    ).value;

    if (question?.answers?.length > 0) {
      question.answers?.forEach((el) => {
        this.deleteAnswer(el.id);
      });
    }
    this.amlService.saveAnswer(answer, question.ask_id).subscribe((res) => {
      this.fetchQuestions();
    });
  }

  saveAnswer(event, question: Question) {
    this.loading = true;

    let id: number;
    id = event.target.id;
    let other: string = '';
    const answer = { answer_id: id, answer_other: other };

    if (
      question?.answers?.length > 0 &&
      event?.target?.value?.toLocaleLowerCase !== 'andet'
    ) {
      question?.answers?.forEach((el) => {
        this.deleteAnswer(el.id);
      });
    }

    this.amlService.saveAnswer(answer, question.ask_id).subscribe((res) => {
      this.fetchQuestions();
    });
  }

  deleteAnswer(ask_id) {
    this.amlService.deleteAnswer(ask_id).subscribe((res) => {
      this.loading = false;
      this.fetchQuestions();
    });
  }

  saveFile(question: Question, e) {
    this.info = '';
    this.error = '';
    let extensionAllowed = {
      png: true,
      PNG: true,
      jpeg: true,
      jpg: true,
      JPG: true,
      JPEG: true,
      gif: true,
      pdf: true,
      PDF: true,
    };

    if (e.target?.files[0].size / 1024 / 1024 > 20) {
      this.uploadinfo.get('picture').setValue('');
      this.error = 'Filstørrelsen skal være mindre end 20MB';
      return;
    }
    if (extensionAllowed) {
      const nam = e.target.files[0].name.split('.').pop();
      if (!extensionAllowed[nam]) {
        this.uploadinfo.get('picture').setValue('');
        this.error = 'Upload venligst en billedefil eller pdf.';
        return;
      }
      this.onFileSelect(question, e?.target?.files[0]);
    }
  }

  onFileSelect(question, input) {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.obj.content = e.target.result;
        this.obj.name = input.files[0].name;
        this.obj.mimetype = input.files[0].type;
        this.uploadFile(question, this.obj);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  uploadFile(question, file) {
    if (question?.answers?.length > 0) {
      let id = question?.answers[0].id;

      this.amlService.fileUpload(id, file).subscribe((res) => {
        this.fetchQuestions();
      });
    }
  }
}
