import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import CriiptoAuth from '@criipto/auth-js';
import { AuthService } from "../services/auth.service";
import { environment } from '../../environments/environment';
import { MitIDResponse, MitIDErrorType, MitIDError } from "../mitid";

type Session = {
    token: string;
    onboarding: boolean;
    unanswered_questions: boolean;
}

type LoginResponse =
    | { status: "OK", type: "LoginSucceeded", session: Session }
    | { status: "OK", type: "ChallengeRequired", id: string }
    | { status: "ERROR", type: LoginErrorType, reference?: string }

type LoginError = Extract<LoginResponse, { status: "ERROR" }>

type LoginErrorType =
    | "InvalidRequest"
    | "InvalidCredentials"
    | "DatabaseError"

type SmsResponse =
    | { status: "OK", type: "LoginSucceeded", session: Session }
    | { status: "ERROR", type: SmsErrorType, reference?: string }

type SmsError = Extract<SmsResponse, { status: "ERROR" }>

type SmsErrorType =
    | "InvalidRequest"
    | "InvalidCode"
    | "DatabaseError"

type Stage =
    | 'password'
    | 'sms';

const formatLoginError = (error: LoginErrorType, referenceId?: string) => {
    switch (error) {
        case "InvalidCredentials":
            return "Forkert brugernavn eller adgangskode.";
        case "InvalidRequest":
            return "Der skete en fejl i forbindelse med login. Prøv igen senere, eller kontakt os for hjælp.";
        case "DatabaseError":
        default:
            if (referenceId) {
                return "Der skete en fejl i forbindelse med login. Prøv igen, eller kontakt os, og oplys referencekoden nedenfor.";
            } else {
                return "Der skete en fejl i forbindelse med login. Prøv igen, eller kontakt os for hjælp.";
            }
    }
};

const formatSmsError = (error: SmsErrorType, referenceId?: string) => {
    switch (error) {
        case "InvalidRequest":
            return "Der skete en fejl i forbindelse med login. Prøv igen senere, eller kontakt os for hjælp.";
        case "InvalidCode":
            return "Forkert kode. Prøv igen.";
        case "DatabaseError":
        default:
            if (referenceId) {
                return "Der skete en fejl i forbindelse med login. Prøv igen, eller kontakt os, og oplys referencekoden nedenfor.";
            } else {
                return "Der skete en fejl i forbindelse med login. Prøv igen, eller kontakt os for hjælp.";
            }
    }
};

const formatMitIDError = (error: MitIDErrorType, referenceId?: string) => {
    switch (error) {
        case "DatabaseError":
        default:
            if (referenceId) {
                return "Der skete en fejl i forbindelse med MitID-login. Prøv igen, eller kontakt os, og oplys referencekoden nedenfor.";
            } else {
                return "Der skete en fejl i forbindelse med MitID-login. Prøv igen, eller kontakt os for hjælp.";
            }
    }
};

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    loginLoading: boolean = false;
    mitidLoading: boolean = false;
    error: string | null = null;
    referenceId: string | null = null;
    stage: Stage = 'password';
    challengeId: string | null = null;
    form = new FormGroup({
        username: new FormControl({
            value: '',
            disabled: false,

        }, [
            Validators.required,
            Validators.minLength(4),
        ]),
        password: new FormControl(
            {
                value: '',
                disabled: false,
            },
            Validators.compose([Validators.required, Validators.minLength(5)])
        ),
    });
    smsForm = new FormGroup({
        smsCode: new FormControl('', [
            Validators.required,
            Validators.minLength(5),
            Validators.pattern('^[0-9]*$'),
            Validators.maxLength(5),
        ]),
    });

    constructor(
        private router: Router,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        const response = fetch(`${environment.apiDomain}/version`, {
            method: 'GET',
        });

        response
            .then((response) => response.json())
            .then((json) => console.log('API version:', json.version))
            .catch((error) => console.error('Failed to fetch API version:', error));
    }

    async onClickMitID() {
        this.error = null;
        this.referenceId = null;
        this.mitidLoading = true;
        const response = await fetch(`${environment.apiUrl}/login/mitid`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        });
        const json: MitIDResponse = await response.json();

        if (json.status === "ERROR") {
            this.handleMitIDError(json);
            return;
        }

        const criipto = new CriiptoAuth({
            domain: json.parameters.domain,
            clientID: json.parameters.client_id,
            store: sessionStorage,
        });

        criipto.redirect.authorize({
            redirectUri: json.parameters.redirect_uri,
            responseType: 'code',
            acrValues: [`urn:grn:authn:dk:mitid:business`],
            loginHint: 'business_optional',
            state: json.parameters.state,
        });
    }

    private handleMitIDError(error: MitIDError) {
        this.mitidLoading = false;
        this.error = formatMitIDError(error.type, error.reference);
        if (this.error.includes('referencekode')) {
            this.referenceId = error.reference;
        }
    }

    async onClickLogin() {
        this.error = null;
        this.referenceId = null;

        const { username, password } = this.form.value;

        this.loginLoading = true;
        const response = await fetch(`${environment.apiUrl}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password,
            }),
        });
        const json: LoginResponse = await response.json();
        this.loginLoading = false;

        if (json.status === "ERROR") {
            this.handleLoginError(json);
            return;
        }

        if (json.type === "LoginSucceeded") {
            sessionStorage.setItem('APIToken1', json.session.token);
            this.authService.isLoginSubject.next(true);

            if (json.session.onboarding) {
                this.router.navigateByUrl('onboarding');
            } else if (json.session.unanswered_questions) {
                this.router.navigateByUrl('profil/aml');
            } else {
                this.router.navigateByUrl('dashboard');
            }

            return;
        }

        if (json.type === "ChallengeRequired") {
            this.challengeId = json.id;
            this.stage = 'sms';
            return;
        }

        console.warn('Unknown response type:', response);
    }

    handleLoginError(error: LoginError) {
        this.error = formatLoginError(error.type, error.reference);
        if (this.error.includes('referencekode')) {
            this.referenceId = error.reference;
        }
    }

    async onSubmitSMS() {
        const { smsCode } = this.smsForm.value;

        this.mitidLoading = true;
        const response = await fetch(`${environment.apiUrl}/login/sms`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                challengeId: this.challengeId,
                code: smsCode,
            }),
        });
        const json: SmsResponse = await response.json();
        this.mitidLoading = false;

        if (json.status === "ERROR") {
            this.handleSmsError(json);
            return;
        }

        if (json.type === "LoginSucceeded") {
            sessionStorage.setItem('APIToken1', json.session.token);
            this.authService.isLoginSubject.next(true);

            if (json.session.onboarding) {
                this.router.navigateByUrl('onboarding');
            } else if (json.session.unanswered_questions) {
                this.router.navigateByUrl('profil/aml');
            } else {
                this.router.navigateByUrl('dashboard');
            }
            return;
        }

        console.warn('Unknown response type:', response);
    }

    handleSmsError(error: SmsError) {
        this.stage = 'password';
        this.error = formatSmsError(error.type, error.reference);
        if (this.error.includes('referencekode')) {
            this.referenceId = error.reference;
        }
    }
}