<div class="card m-3">
  <div class="card-header">
    <h2>Spørgsmål vedr. hvidvask og investering</h2>
  </div>
  <div
    class="alert alert-danger"
    *ngIf="!user.onboarding && unanswered_questions > 0"
  >
    Du har {{ unanswered_questions }}
    {{ unanswered_questions === 1 ? "ubesvaret" : "ubesvarede" }} spørgsmål som
    du aht. vores hvidvaskansvar skal besvare for at få adgang til Fundmarket.
  </div>
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col" class="col-1">Dato</th>
          <th scope="col" class="col-1">Stillet til</th>
          <th scope="col" class="col-6">Spørgsmål</th>
          <th scope="col" class="col-2">Svar</th>
          <th scope="col" class="col-1">Status</th>
          <th scope="col" class="col-1">Besvaret af</th>
        </tr>
      </thead>
      <tbody *ngFor="let mq of meta_questions">
        <tr class="border type">
          <td class="text-white text-center" colspan="6">
            {{ mq.description }}
          </td>
        </tr>

        <tr *ngFor="let question of mq.questions">
          <td class="col-1">
            <div *ngIf="question?.answers?.length > 0">
              {{ question?.answers[0]?.answer_date }}
            </div>
          </td>
          <td class="col-1">{{ question.user_name }}</td>
          <td class="" [innerHTML]="question.question"></td>
          <td class="">
            <div class="form-check">
              <div *ngFor="let empty_answer of question.empty_answer">
                <label class="form-check-label">
                  <ng-container
                    *ngIf="
                      question?.answers?.length > 0;
                      then template1;
                      else template2
                    "
                  ></ng-container>

                  <ng-template #template1>
                    <input
                      class="form-check-input"
                      id="{{ empty_answer.answer_id }}"
                      [value]="empty_answer.answer"
                      name="radio{{ question.ask_id }}"
                      type="radio"
                      (click)="toggle($event, question)"
                      (change)="saveAnswer($event, question)"
                      [checked]="
                        question?.answers[0]?.answer === empty_answer.answer
                      "
                    />
                  </ng-template>

                  <ng-template #template2>
                    <input
                      class="form-check-input"
                      id="{{ empty_answer.answer_id }}"
                      [value]="empty_answer.answer"
                      name="radio{{ question.ask_id }}"
                      type="radio"
                      (click)="toggle($event, question)"
                      (change)="saveAnswer($event, question)"
                    />
                  </ng-template>

                  {{ empty_answer.answer }}
                </label>
                <div
                  *ngIf="empty_answer.other_yn === 'Y' && question.showOther"
                >
                  <form (submit)="saveOther(question)" class="form-inline">
                    <div class="row">
                      <div class="form-group row">
                        <div class="col-md-8">
                          <ng-container
                            *ngIf="
                              question?.answers?.length > 0;
                              then template3;
                              else template4
                            "
                          ></ng-container>
                          <ng-template #template3>
                            <input
                              id="id-{{ question?.ask_id }}"
                              maxlength="20"
                              placeholder="Indtast her"
                              type="text"
                              class="form-control"
                              [value]="question?.answers?.[0]?.answer_other"
                            />
                          </ng-template>
                          <ng-template #template4>
                            <input
                              id="id-{{ question?.ask_id }}"
                              maxlength="20"
                              placeholder="Indtast her"
                              type="text"
                              class="form-control"
                              [value]=""
                            />
                          </ng-template>
                        </div>

                        <div class="col-md-4">
                          <button
                            (click)="question.isLoading = true"
                            type="submit"
                            id="{{ question.ask_id }}button"
                            class="btn btn-primary ml-3"
                          >
                            <fa-icon
                              [icon]="['fas', 'spinner']"
                              [hidden]="!question.isLoading"
                              [spin]="question.isLoading"
                            ></fa-icon
                            >Gem
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  *ngIf="
                    question?.answers?.[0]?.answer === empty_answer.answer &&
                    question?.answers?.[0]?.files?.length > 0
                  "
                >
                  <div
                    class="link m-0 text-start"
                    (click)="
                      sharedService.download(question?.answers?.[0]?.files?.[0])
                    "
                  >
                    {{ question?.answers?.[0]?.files?.[0].name }}
                  </div>
                </div>
                <div
                  *ngIf="
                    question?.answers?.[0]?.answer === empty_answer.answer &&
                    question?.answers?.[0]?.upload &&
                    question?.answers?.[0]?.files === null
                  "
                >
                  <form class="form-inline">
                    <div class="row">
                      <div class="form-group row">
                        <div class="col-md-12 col-sm-12 col-lg-12K">
                          <input
                            class="form-control input-width"
                            (change)="onFileSelect(question, $event.target)"
                            type="file"
                            id="picture"
                            required
                            accept="image/*"
                          />
                          <div class="error" *ngIf="error">{{ error }}</div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </td>
          <td class="">
            <div *ngIf="question.answered">
              <button
                type="button"
                style="pointer-events: none"
                class="btn btn-success"
              >
                Ok
              </button>
            </div>
            <div *ngIf="!question.answered">
              <button
                type="button"
                style="pointer-events: none"
                class="btn btn-warning"
              >
                Ikke besvaret
              </button>
            </div>
          </td>
          <td class="">
            <div *ngIf="question.answers?.length > 0">
              {{ question?.answers?.[0]?.answered_by }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-spinner [spinning]="loading"></app-spinner>
