import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AmlService } from 'src/app/services/aml.service';
import { Router } from '@angular/router';
import type {
  Question,
  MetaQuestion,
  EmptyAnswer,
  Answer,
} from '../../../types/index';
import { User } from '@sharedmodels/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-aml-onboarding-first',
  templateUrl: './aml-onboarding-first.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AmlOnboardingFirstComponent implements OnInit {
  question = <Question>{};
  questions: Question[];
  user = <User>{};
  meta_question = <MetaQuestion>{};
  meta_questions: MetaQuestion[];
  empty_answers: EmptyAnswer[];
  empty_answer = <EmptyAnswer>{};
  answers: Answer[];
  answer = <Answer>{};
  radioSelected: any;
  input: string;
  isEnabled = true;
  questionsForm: FormGroup;
  loading: boolean = false;
  meta_questionsFirst: MetaQuestion[];

  @Output() goForward = new EventEmitter<boolean>();

  constructor(
    private amlService: AmlService,
    public authService: AuthService,
    private router: Router,
    public notificationService: NotificationService,
    public navigationService: NavigationService,
    public cdf: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fetchQuestions();
  }

  getLogin() {
    this.authService.getLogin().subscribe((user: User) => {
      this.user = user;
      if (this.user.has_questions) {
        this.goForward.emit(false);
      } else {
        this.goForward.emit(true);
      }
    });
  }

  fetchQuestions() {
    this.loading = true;
    this.amlService.getQuestions().subscribe((res: MetaQuestion[]) => {
      this.loading = false;

      this.meta_questions = [...res];
      this.meta_questionsFirst = [];
      this.meta_questions.forEach((meta_q) => {
        if (meta_q.code === 'PERSONAL' || meta_q.code === 'OTHER') {
          this.meta_questionsFirst.push(meta_q);
        }
      });
      this.meta_questions = [...this.meta_questionsFirst];

      this.meta_questions?.forEach((mq) => {
        mq.questions?.forEach((question) => {
          question.isLoading = false;
        });

        mq.questions?.forEach((question) => {
          if (
            question.answers === null ||
            (question.answers?.length > 0 &&
              question.answers[0].upload === true &&
              question.answers[0].files === null)
          ) {
            question.answered = false;
          } else {
            question.answered = true;
          }
          question?.answers?.forEach((answer) => {
            if (answer?.answer?.toLocaleLowerCase() == 'andet') {
              question.showOther = true;
            }
          });
        });
        this.cdf.detectChanges();
        setTimeout(() => {
          this.notificationService.setNotObs(true);
        });
      });
    });
    this.getLogin();
  }

  toggle(event, q) {
    q.showOther = false;
    let value: string;
    value = event?.target?.value?.toLocaleLowerCase();
    if (value === 'andet') {
      q.showOther = true;
    }
  }

  saveOther(question: Question) {
    this.loading = false;

    let id: number;
    let other: string = '';

    const answer = { answer_id: id, answer_other: other };

    if (question?.empty_answer) {
      question.empty_answer?.forEach((empty_answer) => {
        if (empty_answer?.other_yn.toLocaleLowerCase() === 'y') {
          answer.answer_id = empty_answer.answer_id;
        }
      });
    }

    answer.answer_other = (
      document.getElementById('id-' + question.ask_id) as HTMLInputElement
    ).value;

    if (question?.answers?.length > 0) {
      question.answers?.forEach((el) => {
        this.deleteAnswer(el.id);
      });
    }
    this.amlService.saveAnswer(answer, question.ask_id).subscribe((res) => {
      this.fetchQuestions();
    });
  }

  saveAnswer(event, question: Question) {
    this.loading = false;

    let id: number;
    id = event.target.id;
    let other: string = '';
    const answer = { answer_id: id, answer_other: other };

    if (
      question?.answers?.length > 0 &&
      event?.target?.value?.toLocaleLowerCase !== 'andet'
    ) {
      question?.answers?.forEach((el) => {
        this.deleteAnswer(el.id);
      });
    }

    this.amlService.saveAnswer(answer, question.ask_id).subscribe((res) => {
      this.fetchQuestions();
    });
  }

  deleteAnswer(ask_id) {
    this.amlService.deleteAnswer(ask_id).subscribe((res) => {
      this.fetchQuestions();
    });
  }
}
