import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import type { Upload } from '../../../types/index';
import { Resp } from '@sharedmodels/resp.model';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
})
export class UploadComponent implements OnInit {
  loading: boolean = false;
  files: Upload[] = [];
  file: any = {};
  messageUrl: string = environment.apiUrl;
  url: SafeUrl;
  uploadinfo: FormGroup;
  info: string;
  error: string;
  tooManyFiles: boolean = false;

  @Input()
  child?: boolean;
  @Input()
  vso?: boolean;

  @Output() public sendFile = new EventEmitter<File>();
  @Output() goForward = new EventEmitter<boolean>();
  constructor(
    private profileService: ProfileService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.info = '';
    this.error = '';
    this.uploadinfo = new FormGroup({
      picture: new FormControl('', [Validators.required]),
    });
    if (!this.child && !this.vso) {
      this.getFiles();
    }
  }

  getFiles() {
    this.files = [];
    this.loading = true;
    this.profileService.getFiles().subscribe((files: Upload[]) => {
      this.loading = false;
      if (files?.length > 0) {
        this.goForward.emit(true);
        files?.forEach((file) => {
          if (file?.name != '' && file?.name != null) {
            file.url = this.sanitizer.bypassSecurityTrustResourceUrl(
              (this.messageUrl +
                '/download/u/' +
                file.id +
                '?t=' +
                sessionStorage.getItem('APIToken1')) as string
            );
            if (files?.length > 10) {
              this.goForward.emit(true);
              this.tooManyFiles = true;
            }
            this.files?.push(file);
          }
        });
      } else if (files?.length === 0) {
        this.goForward.emit(false);
        this.info = 'Der er ingen uploadede filer.';
      }
    });
  }

  getFile(e) {
    this.info = '';
    this.error = '';
    let extensionAllowed = {
      png: true,
      PNG: true,
      jpeg: true,
      jpg: true,
      JPG: true,
      JPEG: true,
      gif: true,
      pdf: true,
      PDF: true,
    };

    if (e.target.files[0].size / 1024 / 1024 > 20) {
      this.uploadinfo.get('picture').setValue('');
      this.error = 'Filstørrelsen skal være mindre end 20MB';
      return;
    }
    if (extensionAllowed) {
      var nam = e.target.files[0].name.split('.').pop();
      if (!extensionAllowed[nam]) {
        this.uploadinfo.get('picture').setValue('');
        this.error = 'Upload venligst en imagefil eller pdf.';
        return;
      }
      var file = e.target.files[0];
      this.file.filename = file.name;
      this.file.filetype = file.type;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.file.content = reader.result;
        this.url = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  upload() {
    this.info = '';
    this.error = '';
    this.url = '';
    this.loading = true;
    if (!this.child && !this.vso) {
      if (this.file && this.tooManyFiles === false) {
        this.profileService.uploadFile(this.file).subscribe((res: Resp) => {
          this.loading = false;
          if (res.status.toUpperCase() === 'ERROR') {
            if (res.message) {
              this.error = res.message;
            } else {
              this.error = 'Der er desværre sket en fejl.';
            }
          } else if (res.severity === 'ERROR') {
            this.error = res.hint;
          } else if (res.status === 'OK') {
            this.uploadinfo.get('picture').setValue('');
            this.getFiles();
            this.info = 'Dine ændringer er nu gemt.';
          }
        }),
          (error) => {
            this.loading = false;
            this.error = error;
          };
      } else {
        this.loading = false;
        this.error =
          'Du har uploadet for mange filer. Kontakt fundmarket A/S hvis du mener dette er en fejl.';
      }
    } else if (this.child || this.vso) {
      this.loading = false;
      this.sendFile.emit(this.file);
      this.info = 'Filen er nu uploaded.';
    }
  }
}
