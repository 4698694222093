export const environment = {
  name: 'staging',
  development: false,
  staging: true,
  production: false,
  apiDomain: 'https://node-api.fundmarket.dev',
  apiUrl: 'https://node-api.fundmarket.dev/investor',
  webUrl: 'https://fundmarket.dk/wp-content/uploads',
  fundUrl: 'https://node-api.fundmarket.dev/funds',
  enableDebug: false,
  host: 'https://fundmarket.criipto.id',
  post_logout_redirect_uri: 'https://investor.fundmarket.dev/login',
};
